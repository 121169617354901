import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import MailTemplate from "./mailTemplate";
import OtherMailTemplates from "./OtherEmail";

const MailPage = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const color = "black";
  useEffect(() => {}, []);
  const { adminPermission } = useSelector((state: any) => state.counter);

  if (adminPermission?.is_admin == 2 && !adminPermission?.pages) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        {" "}
        You don't have permission
      </h1>
    );
  }
  return (
    <div className="px-12 py-12">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="border-b border-aqua w-full flex">
            <div className="w-2/4">
              <ul
                className="flex mb-0 list-none flex-wrap flex-row "
                role="tablist"
              >
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 1
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Redeem
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 2
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Bokun Global
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 3
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Other Mail
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="">
            <div className="mt-9 flex-auto">
              <div className="tab-content tab-space mt-2">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <MailTemplate mailType="redeem" />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <MailTemplate mailType="global" />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <OtherMailTemplates />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailPage;
