import { useState } from "react";
import { useSelector } from "react-redux";
import AddBundles from "../../component/places/modal/AddBundles";
import SearchComponent from "../../component/search/SearchComponent";
import TourscardComponent from "../../component/tours/TourscardComponent";

const Bundles = () => {
  const [filterData, setFilterData] = useState<any>();
  const [searchValue, setSearchValue] = useState();
  const bundles = useSelector((state: any) => state.getBundles);
  
  const getSearchData = (data: any, value: any) => {
    setSearchValue(value);
    setFilterData(data);
  };
  const { adminPermission } = useSelector((state: any) => state.counter);

  if (adminPermission?.is_admin == 2 && !adminPermission?.bundels) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        You don't have permission
      </h1>
    );
  }
  return (
    <div>
      <div className="flex justify-between px-12">
        <SearchComponent
          getSearchData={getSearchData}
          data={bundles.Bundles?.data?.data}
        />
        <div className="flex">
          <AddBundles />
          {/* <div className="ml-4">
            <DropDownComponent />
          </div> */}
        </div> 
      </div>
      {!filterData?.length && searchValue ? (
        <h2 className=" mt-4 text-center ">
          {" "}
          <span className=" text-xl text-red-600 ">{searchValue}</span> is not
          available
        </h2>
      ) : null}  
      <div className=" grid  gap-6 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mt-9 mx-14 mb-6">
        {filterData?.length || searchValue
          ? filterData?.map((elm: any) => <TourscardComponent bundle={elm} />)
          : bundles.Bundles?.data?.data?.map((elm: any) => (
              <TourscardComponent bundle={elm} />
            ))}
      </div>
      {/* <button
        className="w-[150px] h-[50px] border rounded border-aqua ml-11 text-aqua mb-9"
        type="button"
        onClick={() => false}
      >
        Save
      </button>
      <button
        className="w-[150px] h-[50px] border  border-aqua rounded bg-aqua text-white ml-6 mb-9"
        type="button"
        onClick={() => false}
      >
        Add Bundles
      </button> */}
    </div>
  );
};

export default Bundles;
