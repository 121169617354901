import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
// import {editReview } from "../../../constant/string";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { editReview } from "../../../constant/string";
import { BaseUrl } from "../../../services/InstanceAxios";
import services from "../../../services/services";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import InputComponent from "../../inputComponent/InputComponent";
import SelectComponent from "../../selectComponent/SelectComponent";
import { uploadAndSetFile, uploadMultipleFiles } from "../../reviews/ReviewHelper";

const ReviewEdit = ({ data, getdata, sourceData }: any) => {
  const [File, setFile] = useState<any>({});
  const [showModal, setShowModal] = React.useState(false);
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const today = new Date().toISOString().split("T")[0];

  const handleImageUrlChange = (e: any) => {
    const value = e.target.value;
    formik.setFieldValue("editImageUrl", value); // Update Formik value
    setImagePaths(value.split(",")); // Convert string back to array
  };


  useEffect(() => {
    (formik as any).values = {};
    const parsedImages = data.imageUrl ? JSON.parse(data.imageUrl) : [];
    setImagePaths(parsedImages);
    if (showModal) {
      (formik as any).values = {
        tourId: data?.tourId,
        reviewTitle: data?.reviewTitle,
        rating: data?.rating,
        review: data?.review,
        userName: data?.userName,
        reviewDate: data?.reviewDate,
        helpfulCount: data?.helpfulCount,
        reviewSourceId: data?.reviewSourceId,
        editImageUrl: Array.isArray(parsedImages) ? parsedImages.join(",") : "",
      };
    }
  }, [showModal, data]);

  const handleAddReviews = async (payload: any) => {
    const payloadFinal = {
      ...payload,
      imageUrl: payload?.editImageUrl,
    };
    try {
      const res:any = await services.editReviews(data.id,payloadFinal);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        formik.resetForm();
        getdata();
        setShowModal(false)
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit form");
    }
  };

  const formik: any = useFormik({
    initialValues: {
      tourId: data?.tourId,
      reviewTitle: data?.reviewTitle,
      rating: data?.rating,
      review: data?.review,
      userName: data?.userName,
      reviewDate: data?.reviewDate,
      helpfulCount: data?.helpfulCount,
      reviewEditSourceId: data?.reviewSourceId,
      editImageUrl: data?.imageUrl ? JSON.parse(data.imageUrl).join(",") : "",
    },
    validationSchema: Yup.object({
      tourId: Yup.string().trim().required("This field is required"),
      reviewTitle: Yup.string().trim().required("This field is required"),
      rating: Yup.string()
        .trim()
        .required("This field is required")
        .matches(/^\d+$/, "The field should have digits only"),
      review: Yup.string().trim().required("This field is required"),
      userName: Yup.string().required("User Name is required"),
      reviewEditSourceId: Yup.string().required("Source is required"),
      editImageUrl: Yup.string()
        .trim()
        .test(
          "is-valid-array",
          "Image URLs should be a comma-separated string of valid URLs",
          (value) => {
            if (!value) return true;
            const urlArray = value.split(",").map((item) => item.trim());
            return (
              urlArray.length > 0 &&
              urlArray.every((url) => Yup.string().url().isValidSync(url))
            );
          }
        ),
    }),
    onSubmit: (values) => {
      // Convert imageUrl to a comma-separated string if it's an array
      const imgAr = JSON.stringify(
        values.editImageUrl.split(",").map((item: any) => item.trim())
      );

      const finalVal = {
        ...values,
        editImageUrl: imgAr,
        tourId: parseInt(values.tourId),
        reviewSourceId: parseInt(values.reviewEditSourceId)
      };
      handleAddReviews(finalVal);
    },
    enableReinitialize: true,
  });

  const handleChangeOnFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadMultipleFiles(e, services, BaseUrl, setImagePaths, formik, "editImageUrl", imagePaths);
  };

  const handleChangeonSingleFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    uploadAndSetFile(e, services, setFile, formik);
  };

  const deleteOnSingleFile = async () => {
    setFile({
      ...File,
      editSourceImage: "",
    });
    formik.setFieldValue("editSourceImage", "");
  };

  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer"
        src={Images.edit}
        alt=""
        onClick={() => setShowModal(true)}
      />
      {showModal ? (
        <>
          <div className="justify-center  box-border  items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[950px] h-[600px] bg-white my-6 rounded overflow-y-auto ">
              {/*content*/}

              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Edit Review</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setImagePaths([]);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}

              <form
                onSubmit={formik.handleSubmit}
                className="w-full border border-aqua rounded-xl px-12 py-11"
              >
                <p className="font-bold font-sans text-xl">Reviews</p>
                <div className="w-full  mx-auto mt-8">
                  {editReview.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponent
                        landing={false}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={elm?.name === "reviewEditSourceId"? sourceData : elm?.option}
                      />
                    ) : elm?.type === "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeonSingleFile}
                        deleteFile={deleteOnSingleFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : elm?.name === "editImageUrl" ? (
                      <>
                        <InputComponent
                          id={elm?.name}
                          handlechange={handleImageUrlChange} // Use the new handler
                          accept={elm?.accept}
                          value={(formik as any)?.values?.[elm?.name]}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          type={elm?.type}
                          error={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("fileInputEdit")?.click()
                          }
                          className="bg-aqua text-brown px-4 py-2 mt-2 flex rounded"
                        >
                          Upload Images
                        </button>
                        <input
                          id="fileInputEdit"
                          type="file"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleChangeOnFile}
                        />
                        <div className="flex flex-wrap mt-4">
                          {imagePaths?.map((path, index) => (
                            <div key={index} className="relative">
                              <button
                                type="button"
                                className="absolute top-0 right-0 font-bold text-sm text-black"
                                onClick={() => {
                                  const updatedImagePaths = imagePaths.filter(
                                    (_, ind) => ind !== index
                                  );

                                  setImagePaths(updatedImagePaths);

                                  formik.setFieldValue(
                                    "editImageUrl",
                                    updatedImagePaths.join(",")
                                  );
                                }}
                              >
                                X
                              </button>
                              <img
                                src={path}
                                alt={`Uploaded  ${index + 1}`}
                                className="w-24 h-24 object-cover m-2"
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <InputComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        max={elm?.type === 'date' ? today : undefined} 
                      />
                    )
                  )}
                </div>
                <div className="flex justify-between mt-6"></div>
                <button
                  className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
                  type="submit"
                >
                  Add
                </button>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ReviewEdit;
