import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MailInputComponent from "./mailInputComponent";
import MultiTourSelection from "./MultiTourSelector";
import services from "../../services/services";
import { toast } from "react-toastify";
import { OtherMailDataType } from "./OtherEmail";

export type MailDataType = {
  id: number;
  duration: string;
  minElapsedTime?: string;
  template: NewTemplateObj[];
  tourlist: string;
  bundlelist: string;
  isEditMode: boolean;
  error: string;
};

export type NewTemplateObj = {
  language: string;
  htmlTemplate: string;
  error: string;
  isRendering: boolean;
  subject: string;
  id: number;
};

function MailTemplate({ mailType }: { mailType: "redeem" | "global" }) {
  const [mailData, setMailData] = useState<MailDataType[]>([]);
  const { Tours, isLoading, isError } = useSelector(
    (state: any) => state.tours
  );
  const {
    Bundles,
    isLoading: bundleLoading,
    isError: bundleError,
  } = useSelector((state: any) => state.getBundles);
  const [reloadData, setReloadData] = useState(false);
  const [templateTours, setTemplateTours] = useState<any[]>([]);
  const [templateBundles, setTemplateBundles] = useState<any[]>([]);
  const [emailContents, setEmailContent] = useState<any[]>([]);

  useEffect(() => {
    if (!Tours || !Tours.data || !Array.isArray(Tours.data.data)) {
      console.error("Tours data is missing or not structured as expected.");
      return;
    }

    const transformData = async () => {
      try {
        const response = await services.getAllMailData(mailType);
        console.log("response valu", response);
        if (!response?.data || !Array.isArray(response?.data)) {
          console.error("Mail data response is missing or not an array.");
          return;
        }
        console.log("before using parse");
        setMailData(
          response.data.map((item: any) => ({
            ...item,
            template: JSON.parse(item.template),
            isEditMode: false,
            error: "",
          }))
        );
      } catch (error: any) {
        toast.error("Something went wrong while fetching mail");
        console.error("Error fetching mail data:", error.message);
      }
    };

    transformData();
  }, [Tours, reloadData]);

  const transformInitialState = (
    entityList: any[],
    type: "tour" | "bundle"
  ) => {
    const transformedData = mailData.map((item) => {
      const selectedData = item[type === "tour" ? "tourlist" : "bundlelist"]
        ? item[type === "tour" ? "tourlist" : "bundlelist"]
            .split("-")
            .map((id: string) => parseInt(id, 10))
        : [];
      const allList = Array.isArray(entityList)
        ? entityList.map((entity: any) => ({
            name: entity?.name || `Unknown ${type}`,
            [type === "tour" ? "tourId" : "bundleId"]: entity?.id || -1,
            isChecked: selectedData.includes(entity?.id),
          }))
        : [];
      return {
        templateId: item.id,
        [type === "tour" ? "tours" : "bundles"]: allList,
      };
    });

    if (type === "tour") {
      setTemplateTours(transformedData);
    } else {
      setTemplateBundles(transformedData);
    }
  };

  useEffect(() => {
    if (!Bundles?.data?.data || mailData.length === 0) {
      console.error("Either Bundles data or MailData is missing.");
      return;
    }
    transformInitialState(Bundles.data.data, "bundle");
  }, [mailData, Bundles]);

  useEffect(() => {
    if (!Tours?.data?.data || mailData.length === 0) {
      console.error("Either Tours data or MailData is missing.");
      return;
    }
    transformInitialState(Tours.data.data, "tour");

    const htmlContentTransform = mailData.map((element) => ({
      templateId: element.id,
      template: element?.template?.map((item) => ({
        ...item,
        isRendering: false,
        error: "",
        id: Math.floor(Math.random() * 3000) + 1,
      })),
    }));

    setEmailContent(htmlContentTransform);
  }, [mailData, Tours]);

  const updateMailDataError = (
    id: number,
    errorMessage: string,
    updater: React.Dispatch<React.SetStateAction<MailDataType[]>>
  ) => {
    updater((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, error: errorMessage } : item
      )
    );
  };

  const validateAllTemplate = (templates: NewTemplateObj[]) => {
    return templates.map((item) => {
      const doc = new DOMParser().parseFromString(
        item.htmlTemplate,
        "text/html"
      );
      const isError = doc.querySelector("parsererror");
      return isError
        ? { ...item, htmlTemplate: "" }
        : { ...item, htmlTemplate: doc.documentElement.outerHTML };
    });
  };

  console.log("mail data changes", mailData);

  const onTourSelect = (e: any, elm: any, templateData: any) => {
    const tours = templateTours.map((temp) => {
      return temp.templateId === templateData.id
        ? {
            ...temp,
            tours: temp.tours.map((value: any) => {
              return value.tourId === elm.tourId
                ? { ...value, isChecked: e.target.checked }
                : value;
            }),
          }
        : temp;
    });
    setTemplateTours(tours);
  };

  const onBundleSelect = (e: any, elm: any, templateData: any) => {
    const bundles = templateBundles.map((temp) => {
      return temp.templateId === templateData.id
        ? {
            ...temp,
            bundles: temp.bundles.map((value: any) => {
              return value.bundleId === elm.bundleId
                ? { ...value, isChecked: e.target.checked }
                : value;
            }),
          }
        : temp;
    });
    setTemplateBundles(bundles);
  };

  const onTourSelectAll = (templateData: any, alltours: any) => {
    const tours = templateTours.map((temp) => {
      return temp.templateId === templateData.id
        ? {
            ...temp,
            tours: alltours,
          }
        : temp;
    });
    console.log("here is the tours", tours);
    setTemplateTours(tours);
  };

  const onBundleSelectAll = (templateData: any, allBundle: any) => {
    const bundle = templateBundles.map((temp) => {
      return temp.templateId === templateData.id
        ? {
            ...temp,
            bundles: allBundle,
          }
        : temp;
    });
    console.log("here is the bundle", bundle);
    setTemplateBundles(bundle);
  };

  const onEmailTemplateChange = (
    mailData: MailDataType | OtherMailDataType,
    elm: NewTemplateObj,
    type: string
  ) => {
    const mailDataId = mailData.id;

    console.log("Inside mail in:", mailDataId, elm, emailContents);
    if (type === "update") {
      const mailContent = emailContents.map((temp) => {
        if (temp.templateId === mailDataId) {
          const updatedTemplate = temp?.template?.map((value: any) => {
            if (value.id === elm.id) {
              return { ...value, ...elm };
            }
            return value;
          });

          return { ...temp, template: updatedTemplate };
        }
        return temp;
      });
      console.log("mail in tours", mailContent);
      setEmailContent(mailContent);
    } else if (type === "remove") {
      const mailcontent = emailContents.map((temp) => {
        if (temp.templateId === mailDataId) {
          return {
            ...temp,
            template: temp.template.filter((item: any) => item.id !== elm.id),
          };
        }
        return temp;
      });
      setEmailContent(mailcontent);
    } else if (type === "add") {
      const mailcontent = emailContents.map((temp) => {
        if (temp.templateId === mailDataId) {
          return {
            ...temp,
            template: [...temp.template, elm],
          };
        }
        return temp;
      });
      setEmailContent(mailcontent);
    }
  };

  const addMoreMails = () => {
    const newar: MailDataType[] = [
      {
        id: Math.floor(Math.random() * 3000) + 1,
        duration: "",
        template: [],
        tourlist: "",
        bundlelist: "",
        isEditMode: true,
        error: "",
        minElapsedTime: "",
      },
      ...mailData,
    ];
    setMailData(newar);
  };

  const removeEmail = async (id: number) => {
    try {
      await services.deleteMailData(id);
      setMailData(mailData.filter((item) => item.id !== id));
      setReloadData((prev) => !prev);
      toast.success("Deleted Succesfully");
    } catch (error: any) {
      toast.error("Deletion failed");
      console.error(`Error removing email:`, error.message);
    }
  };

  const handleSaveMails = async (elm: MailDataType) => {
    if (!elm.duration) {
      console.error(
        "Invalid duration: must be a positive number and not empty."
      );
      updateMailDataError(elm.id, "Duration must be there.", setMailData);
      return;
    }

    const emailTemps = emailContents.find((elem) => elem.templateId === elm.id);
    if (!emailTemps || !Array.isArray(emailTemps.template)) {
      console.error("No email templates found for mail ID:", elm.id);
      updateMailDataError(
        elm.id,
        "No email templates found for this mail ID.",
        setMailData
      );
      return;
    }
    console.log("email temps", emailTemps.template);
    if (emailTemps.template.length === 0) {
      console.error("Invalid template: must not be empty.");
      updateMailDataError(elm.id, "Template must not be empty.", setMailData);
      return;
    }

    const validatedTemplates = validateAllTemplate(emailTemps.template);
    const invalidTemplates = validatedTemplates.filter(
      (template) => template.htmlTemplate === ""
    );

    if (invalidTemplates.length > 0) {
      console.error("Invalid HTML content found in the template.");
      updateMailDataError(
        elm.id,
        "Invalid HTML content in the template.",
        setMailData
      );
      return;
    }

    const tours = templateTours.find(
      (elem) => elem.templateId === elm.id
    )?.tours;
    const selectedTours = tours
      .filter((item: any) => item.isChecked)
      .map((item: any) => item.tourId)
      .join("-");

    const bundles = templateBundles.find(
      (elem) => elem.templateId === elm.id
    )?.bundles;
    const selectedbundles = bundles
      .filter((item: any) => item.isChecked)
      .map((item: any) => item.bundleId)
      .join("-");

    if (!selectedTours && !selectedbundles) {
      console.error("No tours found.");
      updateMailDataError(
        elm.id,
        "No tours and bundles found for this mail.",
        setMailData
      );
      return;
    }
    console.log("selelcted tours", selectedTours, selectedbundles);

    const payload = {
      duration: elm.duration,
      template: validatedTemplates.map(
        ({ language, subject, htmlTemplate }) => ({
          language,
          subject,
          htmlTemplate,
        })
      ),
      tourlist: selectedTours,
      bundlelist: selectedbundles,
      minElapsedTime: mailType === "redeem" ? elm.minElapsedTime : "00:00",
      type: mailType,
    };
    console.log("payload-->", payload, elm.id);
    try {
      const response = await services.saveMailData(elm.id, payload);
      updateMailDataError(elm.id, "", setMailData);
      toast.success("Mail data saved successfully");
      setReloadData((prev) => !prev);
      console.log("Mail data saved successfully:", response.data);
    } catch (error: any) {
      console.error("Error saving mail data:", error.message);
      updateMailDataError(
        elm.id,
        "Failed to save mail data. Try again later.",
        setMailData
      );
    }
  };

  const ErrorMessage: React.FC<{
    message: string;
    id: number;
    onClearError: (id: number) => void;
  }> = ({ message, id, onClearError }) => {
    if (!message?.trim()) return null;

    return (
      <div className="bg-red-100 text-red-700 px-4 py-3 rounded-md flex items-center justify-between shadow-md max-w-md mx-auto mt-4">
        <span className="text-sm">{message}</span>
        <button
          className="text-red-700 font-bold text-lg focus:outline-none"
          onClick={() => onClearError(id)}
        >
          ✖
        </button>
      </div>
    );
  };

  const handleClearError = (id: number) => {
    setMailData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, error: "" } : item))
    );
  };

  console.log("maildate value", mailData);

  return (
    <div className="flex flex-col p-5">
      <button
        type="button"
        className="px-2 py-1 bg-lightaqua border-2 m-1 mb-2 font-roboto font-normal cursor-pointer"
        onClick={addMoreMails}
      >
        + Add More Mail(s)
      </button>

      <div className="flex flex-col gap-8">
        {mailData?.map((item, ind) => (
          <div
            key={ind}
            className="bg-gray-50 border font-roboto font-normal text-black border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none"
          >
            <div className="flex w-full justify-between">
              <h2 className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
                Mail {ind + 1}
              </h2>
              <ErrorMessage
                message={item.error}
                id={item.id}
                onClearError={handleClearError}
              />
              <div className="flex gap-2">
                {!item.isEditMode ? (
                  <button
                    type="button"
                    className="px-2 py-1 bg-lightaqua border-2 font-roboto font-normal cursor-pointer"
                    onClick={() => {
                      setMailData((prev) =>
                        prev.map((item, index) =>
                          index === ind
                            ? { ...item, isEditMode: !item.isEditMode }
                            : item
                        )
                      );
                    }}
                  >
                    Edit Mail {ind + 1}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="px-2 py-1 bg-lightaqua border-2 font-roboto font-normal cursor-pointer"
                    onClick={() => handleSaveMails(item)}
                  >
                    Save Mail {ind + 1}
                  </button>
                )}
                <button
                  type="button"
                  className="px-2 py-1 text-white bg-red-600 border-2 font-roboto font-normal cursor-pointer"
                  onClick={() => removeEmail(item.id)}
                >
                  Delete Mail {ind + 1}
                </button>
              </div>
            </div>

            <div className="flex gap-4 items-center">
              <span className="text-base text-darkgray font-roboto items-start">
                Set how many hours and minutes before the journey the user
                should be notified
              </span>
              <input
                type="text"
                className="border rounded border-slate-300 w-16"
                value={item?.duration ? item.duration.split(":")[0] : ""} // Extract hours from "duration"
                onChange={(e) => {
                  if (!item.isEditMode) return;
                  const newHours = e.target.value.replace(/\D/g, ""); // Allow only digits
                  const [, minutes = "00"] = item.duration.split(":"); // Extract current minutes
                  setMailData(
                    mailData.map((mail, i) =>
                      i === ind
                        ? { ...mail, duration: `${newHours}:${minutes}` }
                        : mail
                    )
                  );
                }}
                placeholder="Hours"
              />{" "}
              hrs
              <input
                type="text"
                className="border rounded border-slate-300 w-16"
                value={item?.duration?.split(":")[1] || ""} // Extract minutes
                onChange={(e) => {
                  if (!item.isEditMode) return;

                  const newMinutes = e.target.value.replace(/\D/g, ""); // Allow only numbers
                  const hours = item?.duration?.split(":")[0] || "0"; // Extract hours
                  setMailData(
                    mailData.map((mail, i) =>
                      i === ind
                        ? { ...mail, duration: `${hours}:${newMinutes}` }
                        : mail
                    )
                  );
                }}
                onBlur={(e) => {
                  // Add padding to minutes on blur
                  const minutes = e.target.value.padStart(2, "0"); // Pad with leading zero if needed
                  const hours = item?.duration?.split(":")[0] || "00"; // Extract hours
                  setMailData(
                    mailData.map((mail, i) =>
                      i === ind
                        ? { ...mail, duration: `${hours}:${minutes}` }
                        : mail
                    )
                  );
                }}
                placeholder="Minutes"
              />{" "}
              min
            </div>
            {mailType === "redeem" && (
              <div className="flex gap-4 items-center">
                <span className="text-base text-darkgray font-roboto items-start">
                  Set how many hours and minutes should elapse after booking
                  before sending redeem mail
                </span>
                {/* Hours Input */}
                <input
                  type="text"
                  className="border rounded border-slate-300 w-16"
                  value={item?.minElapsedTime?.split(":")[0] || ""}
                  onChange={(e) => {
                    if (!item?.isEditMode) return;

                    const newHours = e.target.value.replace(/\D/g, ""); // Allow only numbers
                    const [, minutes = "00"] = item?.minElapsedTime?.split(
                      ":"
                    ) || ["00", "00"]; // Extract current minutes
                    setMailData(
                      mailData.map((mail, i) =>
                        i === ind
                          ? {
                              ...mail,
                              minElapsedTime: `${newHours}:${minutes}`,
                            }
                          : mail
                      )
                    );
                  }}
                  placeholder="Hours"
                />{" "}
                hrs
                {/* Minutes Input */}
                <input
                  type="text"
                  className="border rounded border-slate-300 w-16"
                  value={item?.minElapsedTime?.split(":")[1] || ""}
                  onChange={(e) => {
                    if (!item?.isEditMode) return;

                    const newMinutes = e.target.value.replace(/\D/g, ""); // Allow only numbers
                    const [hours = "00"] = item?.minElapsedTime?.split(":") || [
                      "00",
                      "00",
                    ]; // Extract hours
                    setMailData(
                      mailData.map((mail, i) =>
                        i === ind
                          ? {
                              ...mail,
                              minElapsedTime: `${hours}:${newMinutes}`,
                            }
                          : mail
                      )
                    );
                  }}
                  onBlur={(e) => {
                    // Add padding to minutes on blur
                    const minutes = e.target.value.padStart(2, "0"); // Ensure two digits
                    const [hours = "00"] = item?.minElapsedTime?.split(":") || [
                      "00",
                      "00",
                    ];
                    setMailData(
                      mailData.map((mail, i) =>
                        i === ind
                          ? { ...mail, minElapsedTime: `${hours}:${minutes}` }
                          : mail
                      )
                    );
                  }}
                  placeholder="Minutes"
                />{" "}
                min
              </div>
            )}

            <div className=" w-full flex gap-x-4 mt-4 items-center ">
              <div className="flex flex-col gap-1">
                <label className="block text-base text-darkgray font-roboto">
                  Select Tours from list
                </label>
              </div>

              <MultiTourSelection
                templateData={item}
                data={
                  templateTours?.find((mail) => mail.templateId === item.id)
                    ?.tours
                }
                handleChange={onTourSelect}
                handleChangeAll={onTourSelectAll}
                className=" w-full"
              />
            </div>

            <div className=" w-full flex gap-x-4 mt-4 items-center ">
              <div className="flex flex-col gap-1">
                <label className="block text-base text-darkgray font-roboto">
                  Select bundles from list
                </label>
              </div>

              <MultiTourSelection
                templateData={item}
                data={
                  templateBundles?.find((mail) => mail.templateId === item.id)
                    ?.bundles
                }
                handleChange={onBundleSelect}
                handleChangeAll={onBundleSelectAll}
                className=" w-full"
              />
            </div>

            <MailInputComponent
              key={"Template(s)"}
              mailData={item}
              templateData={
                emailContents.length > 0
                  ? emailContents?.find((mail) => mail.templateId === item.id)
                      ?.template
                  : []
              }
              onEmailTemplateChange={onEmailTemplateChange}
              name={"template"}
              label={"Template(s)"}
              placeholder={"Enter template"}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default MailTemplate;
