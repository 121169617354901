import axios from "axios";

//export const BaseUrl = "https://api.trippytourguide.com";
export const BaseUrl = "https://test-api.trippytourguide.com/";
// export const BaseUrl = "http://localhost:7000/";
export const ImgUrl = `${BaseUrl}/imageUpload/`;
const instance = axios.create({
  baseURL: BaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

export default instance;
