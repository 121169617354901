import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HeaderComponent = () => {
  const [heading, setHeading] = useState<any>("");
  let location = useLocation();
useEffect(() => {
  const path = location.pathname.toLowerCase();
  console.log("path",path)
  const routeToHeadingMap = [
    { key: "/dashboard", heading: "Dashboard" },
    { key: "user", heading: "User" },
    { key: "places", heading: "Places" },
    { key: "tours", heading: "Tours" },
    { key: "mailtemplate", heading: "Mail Template" },
    { key: "bundles", heading: "Bundles" },
    { key: "bookings", heading: "Bookings" },
    { key: "admin", heading: "Admin" },
    { key: "pages", heading: "Pages" },
    { key: "setting", heading: "Setting" },
    { key: "/stoplist", heading: "StopList" },
    { key: "/stopdetails", heading: "StopDetails" },
    { key: "/addsaftyinformation", heading: "Add Safety Information" },
  ];
  const updateHeading =
    routeToHeadingMap.find((route) => path.includes(route.key))?.heading || path;
  setHeading(updateHeading);
}, [location]);

  return (
    <>
      <nav className="navbar  flex items-center justify-between flex-wrap bg-white-500 p-6  border-b border-aqua h-[88px]">
        <div className="flex items-center flex-shrink-0 text-darkGrey ">
          <span className="font-sans font-bold text-xl">{heading}</span>
        </div>
        <div className="flex items-center flex-shrink-0 text-darkGrey ">
          <div>
            {/* <p className="flex items-center flex-shrink-0 text-darkGrey ml-9">
              ADMIN
            </p>
            <p className="break-all font-sans  font-medium">Erin Wilsone</p> */}
          </div>
          {/* <img className="w-16 h-12" src={Images.Ellipse} alt="" /> */}
        </div>
      </nav>
    </>
  );
};

export default HeaderComponent;
