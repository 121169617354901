import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { reviewSource } from "../../constant/string";
import services from "../../services/services";
import EditInputComponent from "../inputComponent/EditInputComponent";
import InputComponent from "../inputComponent/InputComponent";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import { uploadAndSetFile } from "./ReviewHelper";
import ReviewSourceEdit from "./ReviewSourceEdit";
import { toast } from "react-toastify";
// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import type { ReviewSourceType } from "./type";

function ReviewSource({
  sourceData,
  refreshData,
}: {
  sourceData: ReviewSourceType[];
  refreshData: any;
}) {
  const [File, setFile] = useState<any>({});
  const formik: any = useFormik({
    initialValues: {
      sourcesName: "",
      sourcesImage: "",
    },
    validationSchema: Yup.object({
      sourcesName: Yup.string().required("source Name is required"),
    }),
    onSubmit: async (payload: any) => {
      try {
        const res = await services.postReviewSource(payload);
        console.log("API response:", res);
        if (res.status === 201) {
          toast.success(res?.data?.message);
          formik.resetForm();
          await refreshData();
          toast.success("Review source added");
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        console.error("Error submitting form:", error); // Log any errors
        toast.error("Failed to submit form");
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });
  useEffect(() => {
    console.log("Formik errors:", formik.errors, formik.values);
  }, [formik.errors]);
  const handleChangeonSingleFile = async (e: any) => {
    uploadAndSetFile(e, services, setFile, formik);
  };

  const deleteOnSingleFile = async () => {
    setFile({
      ...File,
      sourcesImage: "",
    });
    formik.setFieldValue("sourcesImage", "");
  };

  const handleDeleteReview = async (id: number) => {
    try {
      let sid = typeof id === "number" ? id.toString() : id;
      await services.deleteReviewSource(sid);
      refreshData();
      toast.dismiss();
      toast.success("Review deleted");
    } catch (error) {
      toast.dismiss();
      toast.error("SomeThing Went Wrong");
    }
  };

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full border border-aqua rounded-xl px-12 py-11"
      >
        <p className="font-bold font-sans text-xl"> Review's source</p>
        <div className="w-full  mx-auto mt-8">
          {reviewSource.map((elm: any) =>
            elm?.type === "file" ? (
              <EditInputComponent
                key={elm?.name}
                id={elm?.name}
                handlechange={handleChangeonSingleFile}
                deleteFile={deleteOnSingleFile}
                accept={elm?.accept}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
              />
            ) : (
              <InputComponent
                id={elm?.name}
                handlechange={formik.handleChange}
                accept={elm?.accept}
                value={
                  elm?.type == "file"
                    ? null
                    : (formik as any)?.values?.[elm?.name] || ""
                }
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
              />
            )
          )}
        </div>

        <button
          className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
          type="submit"
        >
          Add
        </button>
      </form>
      <div className="w-full h-full border border-aqua rounded-xl mt-9 px-12 py-11">
        <p className="font-bold font-sans text-xl  ">Uploaded Reviews</p>
        {sourceData?.map((eml: any) => (
          <div className=" w-full h-full  border border-[#74A89F70] mx-auto rounded-md mt-6 px-10  py-6 'font-roboto text-xl text-[#616161]'">
            <div className="flex justify-between ">
              <div className="flex justify-between w-full">
                <button className="flex">
                  <ReviewSourceEdit data={eml} refreshData={refreshData} />
                  <DeleteModalComponent
                    msg={"Make sure to remove source, from reviews first. Are you sure you want to delete"}
                    dlFn={() => {
                      handleDeleteReview(eml?.id);
                    }}
                  />
                </button>
              </div>
            </div>

            <p className="">Source - {eml.sourcesName}</p>
            <p className="">Source Image - {eml.sourcesImage}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewSource;
