import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Images from "../../constant/Images";

export default function MultiTourSelection({
  templateData,
  data,
  handleChange,
  handleChangeAll,
}: any) {
  type TourName = { text: string; language: string }[];
  const getEngTourName = (tourName: string): string => {
    try {
      const parsedTourName: TourName = JSON.parse(tourName);
      if (Array.isArray(parsedTourName)) {
        const obj = parsedTourName.find((item) => item.language === "en");
        if (obj && obj.text) {
          return obj.text;
        }
        if (parsedTourName[0]?.text) {
          return parsedTourName[0].text;
        }
      }
    } catch {
      return tourName;
    }
    return "Unknown";
  };
  const isAllSelected = data?.every((item: any) => item.isChecked);

  // Handle "Select All" toggle
  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    const updatedData = data.map((item: any) => ({
      ...item,
      isChecked: checked,
    }));
    handleChangeAll(templateData, updatedData);
  };

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left flex-1 max-w-[90%]"
      >
        <div className="w-full border border-slate-300 rounded-md">
          <div className="w-full flex gap-x-1.5 rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 overflow-x-auto overflow-y-hidden">
            <div className="flex w-[98%] overflow-x-auto flex-nowrap">
              {isAllSelected ? (
                // If all tours are selected, show "Select All"
                <div className="flex items-center p-2 border border-lightgray rounded-lg flex-shrink-0 mb-2">
                  <p className="text-sm flex items-center w-fit">Select All</p>
                </div>
              ) : (
                data
                  ?.filter((item: any) => item.isChecked)
                  ?.map((elm: any) => (
                    <div
                      key={elm.id}
                      className="flex mr-2 overflow-x-hidden overflow-y-auto flex-shrink-0 mb-2"
                    >
                      <div className="flex items-center p-2 border border-lightgray rounded-lg">
                        <p className="text-sm flex items-center w-fit">
                          {getEngTourName(elm?.tourBundelsDetails?.name) ??
                            getEngTourName(elm?.name)}
                        </p>
                        <span>
                          <img
                            src={Images.closecircle}
                            alt=""
                            onClick={() => {
                              if (!templateData.isEditMode) return;
                              let e = { target: { checked: false } };
                              handleChange(e, elm, templateData);
                            }}
                            className="w-4 h-4 ml-2"
                          />
                        </span>
                      </div>
                    </div>
                  ))
              )}
            </div>
            {templateData.isEditMode && (
              <div className="w-[1%] flex items-center justify-center">
                <Menu.Button>
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400 text-right ml-auto flex justify-end "
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
            )}
          </div>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none flex-1 h-[200px] p-2.5 pt-0 overflow-auto">
            <div className="flex items-center items-start my-2">
              <input
                id="select-all"
                aria-describedby="select-all"
                type="checkbox"
                onChange={handleSelectAll}
                checked={isAllSelected}
                className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
              />
              <label
                htmlFor="select-all"
                className="text-sm ml-3 font-medium text-gray-900"
              >
                Select All
              </label>
            </div>
            {data?.length &&
              data?.map((item: any) => (
                <div className="">
                  <div className="flex items-center items-start my-2">
                    <input
                      id="checkbox-1"
                      aria-describedby="checkbox-1"
                      type="checkbox"
                      onChange={(e) => {
                        handleChange(e, item, templateData);
                      }}
                      checked={item.isChecked} // Use 'checked' instead of 'defaultChecked'
                      value={item.isChecked}
                      className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                    />
                    <label className="text-sm ml-3 font-medium text-gray-900">
                      {getEngTourName(item?.name)}
                    </label>
                  </div>
                </div>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
