import React, { useEffect, useState } from "react";
import PageComponent from "../../component/places/page/PageComponent";
import ArticleComponent from "../../component/article/ArticleComponent";
import ReviewComponent from "../../component/reviews/ReviewComponent";
import { useSelector } from "react-redux";
import VideosComponent from "../../component/videos/VideosComponent";
import ReviewSource from "../../component/reviews/ReviewSource";
import services from "../../services/services";
import { toast } from "react-toastify";
import { ReviewSourceType } from "../../component/reviews/type";

const Pages = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [reviewSources, setReviewSources] = useState<ReviewSourceType[]>([]);
  const color = "black";

  const fetchAllReviews = async () => {
    try {
      const response = await services.getAllReviewSources();
      let transformSource = response?.data?.map((item:any)=>{return {...item,["name"]: item?.sourcesName}})
      setReviewSources(transformSource);
    } catch (error) {
      toast.error("Unable to fetch review source");
      console.error("Error fetching review sources:", error);
    }
  };

  useEffect(() => {
    fetchAllReviews();
  }, []);

  const { adminPermission } = useSelector((state: any) => state.counter);

  if (adminPermission?.is_admin == 2 && !adminPermission?.pages) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        {" "}
        You don't have permission
      </h1>
    );
  }
  return (
    <div className="px-12 py-12">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="border-b border-aqua w-full flex">
            <div className="w-2/4">
              <ul
                className="flex mb-0 list-none flex-wrap flex-row "
                role="tablist"
              >
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 1
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    FAQ’s
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 2
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Article
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 3
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Reviews
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 4
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(4);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Review's Source
                  </a>
                </li>
                <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 5
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(5);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Video
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="w-2/4 flex justify-end">
              <select
                id="countries"
                className="h-9 w-24 border border-aqua rounded-md outline-none"
              >
                <option selected>Tours</option>
              </select>
            </div> */}
          </div>

          <div className="">
            <div className="mt-9 flex-auto">
              <div className="tab-content tab-space mt-2">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <PageComponent />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <ArticleComponent />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <ReviewComponent sourceData={reviewSources} />
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <ReviewSource sourceData={reviewSources} refreshData={fetchAllReviews} />
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <VideosComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
// export default function Pages() {
//     return (
//       <>
//         return <Pages color="slate" />;
//       </>
//     );
//   }
