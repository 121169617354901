import React, { useState } from "react";

interface DateFilterModalProps {
  onClose: () => void;
  onConfirm: (fromDate: string, toDate: string) => void;
}

const DateFilterModal: React.FC<DateFilterModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleConfirm = () => {
    if (!fromDate || !toDate) {
      alert("Please select both From and To dates.");
      return;
    }
    onConfirm(fromDate, toDate);
    onClose();
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300 w-96 relative">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          ✕
        </button>

        <h2 className="text-xl font-semibold mb-4">Select Date Range</h2>

        {/* From Date */}
        <label className="block text-sm font-medium text-gray-700">
          From Date:
        </label>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        />

        {/* To Date */}
        <label className="block text-sm font-medium text-gray-700">
          To Date:
        </label>
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        />

        {/* Buttons */}
        <div className="flex justify-end gap-2">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded-md bg-red-600"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded-md bg-green-600"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateFilterModal;
