import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import { STORAGE_PATH, bundlesInput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckBox from "../../checkbox/CheckBox";
import services from "../../../services/services";
import { useAppDispatch } from "../../../store/app/store";
import { getallBundlesThank } from "../../../store/features/getBundlesSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EmailInputComponent from "../../inputComponent/EmailInputComponent";
import SelectComponent from "../../selectComponent/SelectComponent";

type NewTemplateObj = {
  language: string;
  isRendering: boolean;
  text: string;
  error: any;
};

const AddBundles = () => {
  const { Tours, isLoading, isError, isSuccess } = useSelector(
    (state: any) => state.tours
  );
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [File, setFile] = useState<any>({});
  const [bundleTours, setBundleTours] = useState<any>([]);
  const [tourInBundle, setTourInBundle] = useState<any>({});
  let img = "/root/imageUpload/1682793272054WildHorse.jpg";
  const handleAddBunleTours = (tour: any) => {
    setBundleTours((prev: any) => [...prev, tour]);
    setTourInBundle({
      ...tourInBundle,
      [tour.id]: true,
    });
  };
  const handleFilterBundleTours = (tour: any) => {
    setBundleTours(bundleTours.filter((item: any) => item?.id !== tour?.id));
    delete tourInBundle[tour.id];
  };

  const setTemplateData = (
    updater:
      | NewTemplateObj[]
      | ((prevData: NewTemplateObj[]) => NewTemplateObj[])
  ) => {
    if (typeof updater === "function") {
      let value = updater(formik.values.bundleEmailTemplate);
      formik.setFieldValue("bundleEmailTemplate", value);
    } else {
      formik.setFieldValue("bundleEmailTemplate", updater);
    }
  };

  const validateAllTemplate = (templates: NewTemplateObj[]) => {
    return templates.map((item) => {
      const doc = new DOMParser().parseFromString(item.text, "text/html");
      const isError = doc.querySelector("parsererror");
      return isError
        ? { ...item, text: "" }
        : { ...item, text: doc.documentElement.outerHTML };
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      productId: "",
      bokunId: "",
      imageUrl: "",
      description: "",
      retailPrice: "",
      sellingPrice: "",
      bundleEmailTemplate: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is  required"),
      productId: Yup.string()
        .trim()
        .required("This field is required")
        .test("no-spaces", "Name cannot contain spaces", (value: any) => {
          return value && !/\s/.test(value);
        }),
      bundleEmailTemplate: Yup.array()
        .min(1, "At least one template is required")
        .required("This field is required"),
      bokunId: Yup.number().typeError("Please enter number value only"),
      description: Yup.string().trim().required("This field is  required"),
      sellingPrice: Yup.number()
        .typeError("Please enter a number value only")
        .nullable()
        .transform((value, originalValue) => {
          console.log("originalValue: ", originalValue);
          return originalValue.trim() === "" ? null : parseFloat(originalValue);
        })
        .lessThan(
          Yup.ref("retailPrice"),
          "Selling price is less than or equal to retail price"
        ),
      retailPrice: Yup.number()
        .notOneOf([0], "Zero and negative values not accepted")
        .required("This field is  required"),
      // .min(1)
      // .moreThan(-1, "Negative values not accepted"),
      imageUrl: Yup.string().trim().required("This field is  required"),
    }),
    onSubmit: (values) => {
      if (bundleTours?.length) {
        formik.handleReset(formik.values);
        const validatedTemplates = validateAllTemplate(
          values.bundleEmailTemplate
        );
        const templateSave = validatedTemplates.map((item: NewTemplateObj) => ({
          text: item.text,
          language: item.language,
        }));
        handleAddTour({
          ...values,
          bundleEmailTemplate: JSON.stringify(templateSave),
          imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
          // sellingPrice: parseInt(values?.sellingPrice),
        });
      } else {
        toast.error("Please add Tours");
      }
    },
  });

  const handleAddTour = async (payload: any) => {
    const finalPayoad = {
      ...payload,
      // retailPrice: payload.retailPrice || null,
      sellingPrice: parseFloat(payload.sellingPrice) || null,
      // retailPrice: parseInt(payload.retailPrice) || "",
      //   sellingPrice: (payload.sellingPrice <= 0 || !payload.sellingPrice || payload.sellingPrice !== "") ?  parseInt(payload.retailPrice) : parseInt(payload.sellingPrice),
      bundelsDetails: bundleTours?.map((item: any) => ({
        placeId: item?.placeId,
        tourId: item?.id,
      })),
    };
    const res: any = await services.createBundles(finalPayoad);
    if (res?.status) {
      toast.success("bundels created successfully");
      dispatch(getallBundlesThank());
      setBundleTours([]);
      setTourInBundle({});
      setShowModal(false);
    } else {
      toast.error("someThing Went Wrong");
    }
  };

  const handleChangeOnFile = async (e: any) => {
    try {
      if (
        e?.target?.files[0]?.type == "image/jpg" ||
        e?.target?.files[0]?.type == "image/png" ||
        e?.target?.files[0]?.type == "image/jpeg" ||
        e?.target?.files[0]?.type == "image/svg"
      ) {
        formik.values.imageUrl = e.target.value;
        const res = await services.uploadImage(e.target.files[0]);
        if (res?.data?.data?.url[0]?.path) {
          setFile({
            ...File,
            [e.target.name]: res?.data?.data?.url[0]?.path,
          });
          formik.errors.imageUrl = "";
        } else {
          formik.values.imageUrl = "";
          formik.errors.imageUrl = "Please select correct img";
        }
      } else {
        toast.error("Only user png,jpeg,jpg,svg format");
      }
    } catch (error) {
      toast.error("Something went wrong while uploading img");
    }
  
  };
  return (
    <>
      <button
        className="rounded-3xl bg-dimblack flex  justify-center items-center w-36 h-10 mt-6"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <img
          className="h-3.5 w-3.5 mr-2 font-roboto font-medium text-xl"
          src={Images.add}
          alt=""
        />
        <p className="text-white">Add Bundles</p>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}

              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Bundles</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                      setBundleTours([]);
                      setTourInBundle({});
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form onSubmit={formik.handleSubmit} className="px-12 py-9">
                <div className="">
                  {bundlesInput.map((elm: any) =>
                    elm?.key === "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={elm?.option}
                      />
                    ) : elm?.type == "email" ? (
                      <>
                   
                        <EmailInputComponent
                          key={elm?.name}
                          templateData={formik.values.bundleEmailTemplate}
                          setTemplateData={setTemplateData}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          err={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                      </>
                    ) : (
                      <InputComponent
                        id={elm?.name}
                        handlechange={
                          elm?.type == "file"
                            ? handleChangeOnFile
                            : formik.handleChange
                        }
                        accept={elm?.accept}
                        value={
                          elm?.type == "file"
                            ? null
                            : (formik as any)?.values?.[elm?.name]
                        }
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                  <div className=" w-full justify-between mt-4">
                    <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto">
                      Tours
                    </label>
                    <CheckBox
                      data={Tours?.data?.data}
                      handleFilterBundleTours={handleFilterBundleTours}
                      handleAddBunleTours={handleAddBunleTours}
                      tourInBundle={tourInBundle}
                      bundleTours={bundleTours}
                      className=" w-full"
                    />
                  </div>

                  {/* <div>
                  <AddStopsComponent />{" "}
                </div> */}
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setBundleTours([]);
                      setShowModal(false);
                      setTourInBundle({});
                      // setBundleTours([]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default AddBundles;
