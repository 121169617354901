import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Images from "../../constant/Images";
import { addReview } from "../../constant/string";
import { BaseUrl } from "../../services/InstanceAxios";
import services from "../../services/services";
import { utils } from "../../utils/util";
import InputComponent from "../inputComponent/InputComponent";
import DeleteModalComponent from "../places/modal/DeleteModalComponent";
import ReviewEdit from "../places/modal/ReviewEdit";
import SelectComponent from "../selectComponent/SelectComponent";
import { uploadMultipleFiles } from "./ReviewHelper";
// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import type { ReviewSourceType } from "./type";

const ReviewComponent = ({sourceData}:{sourceData:ReviewSourceType[]}) => {
  const [allreviews, setAllreviews] = useState([]);
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const { Tours } = useSelector(
    (state: any) => state.tours
  );

  const formik: any = useFormik({
    initialValues: {
      reviewTitle: "",
      rating: "",
      review: "",
      tourId: "",
      userName: "",
      reviewDate: "",
      helpfulCount: "",
      imageUrl: "",
      reviewSourceId: "",
    },
    validationSchema: Yup.object({
      reviewTitle: Yup.string().trim().required("This field is required"),
      rating: Yup.string()
        .trim()
        .required("This field is required")
        .matches(/^\d+$/, "The field should have digits only"),
      review: Yup.string().trim().required("This field is required"),
      tourId: Yup.string().required("This field is required"),
      userName: Yup.string().required("User Name is required"),
      reviewSourceId: Yup.string().required("Source is required"),
      imageUrl: Yup.string()
        .trim()
        .test(
          "is-valid-array",
          "Image URLs should be a comma-separated string of valid URLs",
          (value) => {
            if (!value) return true; 
            const urlArray = value.split(",").map((item) => item.trim()); 
            return (
              urlArray.length > 0 &&
              urlArray.every((url) => Yup.string().url().isValidSync(url))
            )
          }
        ),
    }),
    onSubmit: (values) => {
      // Convert imageUrl to a comma-separated string if it's an array
      const imgAr = JSON.stringify(
        values.imageUrl.split(",").map((item) => item.trim())
      );
      const finalVal = {
        ...values,
        imageUrl: imgAr,
        tourId: parseInt(values.tourId),
      };
      handleAddReviews(finalVal);
    },
  });

  const getdata = async () => {
    try {
      const res = await services.allReviews();

      setAllreviews(res?.data?.data);
    } catch (err) {
      console.error("error while getting review",err)
    }
  };
  useEffect(() => {
    if (!allreviews.length) {
      getdata();
    }
  }, []);

  const handleImageUrlChange = (e: any) => {
    const value = e.target.value;
    formik.setFieldValue("imageUrl", value); 
    setImagePaths(value.split(",")); 
  };

  const handleAddReviews = async (payload: any) => {
    try {
      const res = await services.postAddReviews(payload)
        toast.success(res?.data?.message);
        formik.resetForm(); // Reset the form after successful submission
        getdata();
    } catch (error) {
      console.error("Error submitting form:", error); // Log any errors
      toast.error("Failed to submit form");
    }
  };
  const handleDeleteReview = async (id: number) => {
    const res: any = await services.deleteReviews(id);

    if (res.status == 200) {
      toast.dismiss();
      toast.success(res?.data?.message);
      getdata();
    } else {
      toast.dismiss();
      toast.error("SomeThing Went Wrong");
    }
  };

  const handleChangeOnFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    uploadMultipleFiles(e, services, BaseUrl, setImagePaths, formik, "imageUrl", imagePaths);
  };


  useEffect(() => {
    console.log("Formik errors:", formik.errors);
  }, [formik.errors]);

  const tourMap = Tours?.data?.data.map((tour: any) => {
    return { ...tour, name: utils.getEngTourName(tour.name) };
  });

  const today = new Date().toISOString().split("T")[0];

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full border border-aqua rounded-xl px-12 py-11"
      >
        <p className="font-bold font-sans text-xl">Reviews</p>
        <div className="w-full  mx-auto mt-8">
          {addReview.map((elm: any) =>
            elm?.key == "option" ? (
              <SelectComponent
                landing={false}
                id={elm?.name}
                handlechange={formik.handleChange}
                value={(formik as any)?.values?.[elm?.name]}
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
                option={elm?.key1 == "tourId" ? tourMap : elm?.name === "reviewSourceId"? sourceData : elm?.option}
              />
            ) : elm?.name === "imageUrl" ? (
              <>
                <InputComponent
                  id={elm?.name}
                  handlechange={handleImageUrlChange} // Use the new handler
                  accept={elm?.accept}
                  value={(formik as any)?.values?.[elm?.name]}
                  name={elm?.name}
                  label={elm?.label}
                  placeholder={elm?.placeHolder}
                  type={elm?.type}
                  error={(formik as any).errors[elm.name]}
                  touched={(formik as any).touched[elm.name]}
                />
                <button
                  type="button"
                  onClick={() => document.getElementById("fileInput")?.click()}
                  className="bg-aqua text-brown px-4 py-2 mt-2 rounded"
                >
                  Upload Images
                </button>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  multiple // Allow multiple files
                  style={{ display: "none" }} // Hide the file input
                  onChange={handleChangeOnFile}
                />
                <div className="flex flex-wrap mt-4">
                  {imagePaths.map((path, index) => (
                    <div key={index} className="relative">
                      <button
                        type="button"
                        className="absolute top-0 right-0 font-bold text-sm text-black"
                        onClick={() => {
                          // Remove the image at the current index
                          const updatedImagePaths = imagePaths.filter(
                            (_, ind) => ind !== index
                          );

                          // Update the imagePaths state
                          setImagePaths(updatedImagePaths);

                          // Update the Formik value
                          formik.setFieldValue(
                            "imageUrl",
                            updatedImagePaths.join(",")
                          );
                        }}
                      >
                        X
                      </button>
                      <img
                        src={path} // Use the full image URL
                        alt={`Uploaded  ${index + 1}`}
                        className="w-24 h-24 object-cover m-2"
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <InputComponent
                id={elm?.name}
                handlechange={formik.handleChange}
                accept={elm?.accept}
                value={
                  elm?.type == "file"
                    ? null
                    : (formik as any)?.values?.[elm?.name] || ""
                }
                name={elm?.name}
                label={elm?.label}
                placeholder={elm?.placeHolder}
                type={elm?.type}
                error={(formik as any).errors[elm.name]}
                touched={(formik as any).touched[elm.name]}
                max={elm?.type === 'date' ? today : undefined} 
              />
            )
          )}
        </div>

        <button
          className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
          type="submit"
        >
          Add
        </button>
      </form>
      <div className="w-full h-full border border-aqua rounded-xl mt-9 px-12 py-11">
        <p className="font-bold font-sans text-xl  ">Uploaded Reviews</p>
        {allreviews?.map((eml: any) => (
          <div className=" w-full h-full  border border-[#74A89F70] mx-auto rounded-md mt-6 px-10  py-6 'font-roboto text-xl text-[#616161]'">
            <div className="flex justify-between ">
              <div className="flex justify-between w-full">
                <div className="flex">
                  {eml.reviewTitle}
                  <div className="ml-14 flex">
                    {[1, 2, 3, 4, 5]
                      .filter((item, index) => index < eml?.rating)
                      .map((_,index) => (
                        <img key={index} src={Images.yStar} alt="" className="mr-4" />
                      ))}
                    {[5, 4, 3, 2, 1]
                      ?.filter((_, index) => index >= eml?.rating)
                      .map((_,index) => (
                        <img key={index} src={Images.star} alt="" className="mr-4" />
                      ))}
                  </div>
                </div>

                <button className="flex">
                  <ReviewEdit data={eml} getdata={getdata} sourceData = {sourceData} />
                  <DeleteModalComponent
                    dlFn={() => {
                      handleDeleteReview(eml?.id);
                    }}
                  />
                </button>
              </div>
            </div>
            <div className="text-lg">Review date - {eml.reviewDate}</div>
            <div className="text-lg">User Name - {eml.userName}</div>
            <div className="text-lg">
              User Helpful Count - {eml.helpfulCount}
            </div>
            <div className="text-lg">Image Url - {eml.imageUrl}</div>
            <p className="">Review text - {eml.review}</p>
            <p className="">Source - {sourceData?.find((item)=>item.id == eml.reviewSourceId)?.name || ""}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewComponent;
