import React from "react";
import "./inputStyle.css";
const InputComponent = ({
  label,
  value,
  placeholder,
  type,
  name,
  handlechange,
  error,
  touched,
  handleBlur,
  id,
  accept,
  max
}: any) => {
  return (
    <div className="">
      <label className="flex mb-2 mt-3 text-xl font-bold text-darkgray font-roboto justify-start">
        {label}
      </label>
        <input
          accept={`${accept}/*`}
          id={id}
          name={name}
          onChange={handlechange}
          type={type}
          autoComplete="off"
          value={value}
        className=" bg-gray-50 border font-normal font-roboto text-black border-slate-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 outline-none"
          placeholder={placeholder}
          onBlur={handleBlur}
          max={max}
        // required
        />
      {error && touched && <p className="text-red-600">{error}</p>}
    </div>
  );
};

export default InputComponent;
