import { useState } from "react";
import { languages } from "../../constant/Languages";
import "./inputStyle.css";
import { MailDataType, NewTemplateObj } from "./mailTemplate";
import { OtherMailDataType } from "./OtherEmail";

type MailInputComponentProps = {
  label: string;
  placeholder: string;
  variables?: string;
  name: string;
  templateData: NewTemplateObj[];
  mailData: MailDataType | OtherMailDataType;
  onEmailTemplateChange: (
    mailData: MailDataType | OtherMailDataType,
    elm: NewTemplateObj,
    type: string
  ) => void;
};

const MailInputComponent: React.FC<MailInputComponentProps> = ({
  label,
  mailData,
  placeholder,
  variables,
  name,
  templateData,
  onEmailTemplateChange,
}) => {
  const [showInputField, setShowInputField] = useState(false);
  const [templateState, setTemplateState] = useState<NewTemplateObj>({
    language: "",
    htmlTemplate: "",
    error: "",
    isRendering: false,
    subject: "",
    id: Math.floor(Math.random() * 3000) + 1, // Unique ID for new templates
  });

  // Validate HTML content
  const validateHTML = (html: string): string | false => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.querySelector("parsererror") ? false : html;
  };

  // Save a new template
  const handleSave = () => {
    if (!templateState.language || !templateState.htmlTemplate) {
      setTemplateState((prev) => ({
        ...prev,
        error: "Language and HTML template are required.",
      }));
      return;
    }

    const validatedHTML = validateHTML(templateState.htmlTemplate);
    if (!validatedHTML) {
      setTemplateState((prev) => ({
        ...prev,
        error: "Invalid HTML content. Please correct it and try again.",
      }));
      return;
    }
    console.log("inside save", templateState);
    onEmailTemplateChange(
      mailData,
      { ...templateState, htmlTemplate: validatedHTML },
      "add"
    );

    setTemplateState({
      language: "",
      htmlTemplate: "",
      error: "",
      isRendering: false,
      subject: "",
      id: Math.floor(Math.random() * 3000) + 1,
    });

    setShowInputField(false);
  };

  const toggleRenderView = (index: number) => {
    const updatedTemplate = {
      ...templateData[index],
      isRendering: !templateData[index].isRendering,
    };
    onEmailTemplateChange(mailData, updatedTemplate, "update");
  };

  const handleInputChange = (
    field: keyof NewTemplateObj,
    value: string,
    index: number
  ) => {
    if (!mailData.isEditMode) return;
    console.log("input cahnge", field, value, index);
    const updatedTemplate = { ...templateData[index], [field]: value };
    console.log("update temp", updatedTemplate);
    onEmailTemplateChange(mailData, updatedTemplate, "update");
  };

  const handleRemove = (index: number) => {
    const templateToRemove = templateData[index];
    onEmailTemplateChange(mailData, templateToRemove, "remove");
  };

  return (
    <div>
      <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
        {label}
      </label>
      {variables && (
        <div className="  mb-2 mt-3 text-sm font-bold text-gray ">
          {variables}
        </div>
      )}
      <div className="bg-gray-50 font-roboto font-normal text-black text-gray-900 text-sm block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
        <div>
          {templateData?.map((item, index) => (
            <div
              key={item.language + index}
              className="mb-4 border border-slate-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none focus:ring-blue-500 focus:border-blue-500 p-2.5 rounded-lg"
            >
              <div className="flex gap-2 items-center">
                <span className="text-base text-darkgray font-roboto items-start">
                  Select language
                </span>
                <select
                  value={item.language}
                  onChange={(e) =>
                    handleInputChange("language", e.target.value, index)
                  }
                  className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
                >
                  <option value="">Select Language</option>
                  {languages.map((lang) => (
                    <option key={lang.code} value={lang.name}>
                      {lang.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex gap-2 my-2 items-center">
                <span className="text-base text-darkgray font-roboto items-start">
                  Enter subject of email
                </span>
                <input
                  type="text"
                  className="mb-2 flex-1 bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
                  value={item.subject}
                  onChange={(e) =>
                    handleInputChange("subject", e.target.value, index)
                  }
                  placeholder="Enter subject"
                />
              </div>
              <h3 className="mb-2 text-base text-darkgray font-roboto items-start">
                Enter email template:
              </h3>
              {item.isRendering ? (
                <div
                  className="w-full h-200px overflow-y-auto bg-gray-50 border border-slate-300 rounded-lg p-2.5"
                  dangerouslySetInnerHTML={{ __html: item.htmlTemplate }}
                />
              ) : (
                <textarea
                  className="w-full bg-gray-50 border font-normal font-roboto text-black border-slate-300 text-gray-900 text-sm rounded-lg block p-2.5 outline-none"
                  style={{ height: 200 }}
                  value={item.htmlTemplate}
                  onChange={(e) =>
                    handleInputChange("htmlTemplate", e.target.value, index)
                  }
                  placeholder="Enter HTML content here..."
                />
              )}
              {item.error && <p className="text-red-500 mt-2">{item.error}</p>}
              <div className="flex gap-2 mt-2">
                <button
                  className="border rounded bg-aqua text-white px-2 py-2"
                  type="button"
                  onClick={() => toggleRenderView(index)}
                >
                  {item.isRendering ? "View HTML" : "Render"}
                </button>
                {mailData.isEditMode && (
                  <button
                    onClick={() => handleRemove(index)}
                    type="button"
                    className="ml-2 px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>

        {showInputField && (
          <div className="mb-2">
            <div className="flex gap-2 items-center mb-2">
              <span className="text-base text-darkgray font-roboto items-start">
                Select language
              </span>
              <select
                value={templateState.language}
                onChange={(e) =>
                  setTemplateState((prev) => ({
                    ...prev,
                    language: e.target.value,
                  }))
                }
                className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
              >
                <option value="">Select Language</option>
                {languages.map((lang) => (
                  <option key={lang.code} value={lang.name}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <span className="text-base text-darkgray font-roboto items-start">
                Enter subject of email
              </span>
              <input
                type="text"
                className="mb-2 flex-1 bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
                value={templateState.subject}
                onChange={(e) =>
                  setTemplateState((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }))
                }
                placeholder="Enter subject"
              />
            </div>
            <textarea
              className="w-full bg-gray-50 border font-normal font-roboto text-black border-slate-300 text-gray-900 text-sm rounded-lg block p-2.5 outline-none"
              style={{ height: 200 }}
              value={templateState.htmlTemplate}
              onChange={(e) =>
                setTemplateState((prev) => ({
                  ...prev,
                  htmlTemplate: e.target.value,
                }))
              }
              placeholder="Enter HTML content ..."
            />
            {templateState.error && (
              <p className="text-red-500 mt-2">{templateState.error}</p>
            )}
            <div className="flex gap-2 mt-2">
              <button
                onClick={handleSave}
                className="border rounded bg-aqua text-white px-2 py-2"
              >
                Save
              </button>
              <button
                onClick={() => setShowInputField(false)}
                className="ml-2 px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {mailData.isEditMode && (
          <button
            type="button"
            className="px-2 py-1 bg-lightaqua border-2 m-1 font-roboto font-normal cursor-pointer"
            onClick={() => setShowInputField(true)}
          >
            + Add More Template
          </button>
        )}
      </div>
    </div>
  );
};

export default MailInputComponent;
