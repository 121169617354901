
export const uploadMultipleFiles = async (
  e: React.ChangeEvent<HTMLInputElement>,
  services: any,
  BaseUrl: string,
  setImagePaths: React.Dispatch<React.SetStateAction<string[]>>,
  formik: any,
  fieldName: string,
  existingPaths: string[]
) => {
  if (e.target.accept !== "image/*" || !e.target.files?.length) return;

  const files = Array.from(e.target.files);
  const newImagePaths: string[] = [];

  try {
    for (const file of files) {
      const res = await services.uploadImage(file);
      if (res?.data?.data?.url[0]?.path) {
        const path = res.data.data.url[0].path.split("/");
        const pathval = path[path.length - 1];
        const fullImageUrl = `${BaseUrl}imageUpload/${pathval}`;
        newImagePaths.push(fullImageUrl);
      }
    }

    const updatedPaths = [...existingPaths, ...newImagePaths];

    setImagePaths(updatedPaths);
    formik.setFieldValue(fieldName, updatedPaths.join(",")); // Convert array to string
  } catch (error) {
    console.error("Error uploading images:", error);
    formik.setFieldError(fieldName, "Image upload failed");
  }
};


export const uploadAndSetFile = async (
  e: React.ChangeEvent<HTMLInputElement>,
  services: any,
  setFile: React.Dispatch<React.SetStateAction<any>>,
  formik: any
) => {
  if (!e.target.files?.length) return;

  const file = e.target.files[0];
  const fieldName = e.target.name;

  try {
    const res = await services.uploadImage(file);

    if (res?.data?.data?.url[0]?.path) {
      const path = res.data.data.url[0].path.split("/");
      const pathval = path[path.length - 1];

      setFile((prevFile: any) => ({
        ...prevFile,
        [fieldName]: pathval,
      }));

      formik.setFieldValue(fieldName, pathval);
    } else {
      setFile((prevFile: any) => ({
        ...prevFile,
        [fieldName]: "",
      }));

      formik.setFieldValue(fieldName, "");
      formik.setFieldError(fieldName, "Image upload failed");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    formik.setFieldError(fieldName, "Error uploading image");
  }
};

