import services from "../../services/services";

const EditInputComponent = ({
  label,
  value,
  type,
  name,
  handlechange,
  error,
  touched,
  handleBlur,
  id,
  accept,
  deleteFile,
  deleteImageUri
}: any) => {

async function removeHandler(fileToDelete:any){
  try {
    const imageUrl = fileToDelete;
    const res = await services.removeImage(imageUrl);
  
    if (res?.status === 200) {   
      if(name =="imageUrl"){
        deleteImageUri()
      }else{
        deleteFile()
      }
    } else {
      console.log("Failed to remove Image");
    }
  } catch (error) {
    console.log("Error removing Image:", error);
  }
}

  return (
    <div className="">
      <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
        {label}
      </label>
      <div className="bg-gray-50 border font-roboto font-normal text-black border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none">
        {!(value && value?.length>0) &&<label htmlFor={name} className="font-roboto font-normal">
          <span
            className="px-[6px] py-[1px] text-[14px]  bg-[#F0F0F0] border-[1px] m-1 font-roboto font-normal"
          >
            {" "}
            Choose File
          </span>
        </label>
}
        <input
        className="font-roboto font-normal "
          accept={`${accept}/*`}
          id={id}
          name={name}
          onChange={handlechange}
          type={type}
          //   value={value}
          //   placeholder={placeholder}
          onBlur={handleBlur}
          hidden
        />

        <div>
        <span>{value} 

       {(value && value?.length>0) && <button
              onClick={() => removeHandler(value)}
              type={"button"}
              className="ml-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
            >
              Remove
            </button>
       }
            </span>
        </div>
        
      </div>
      
      {error && touched && <p className="text-red-600">{error}</p>}
    </div>
  );
};

export default EditInputComponent;
