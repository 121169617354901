import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Images from "../../constant/Images";
import { editReviewSource } from "../../constant/string";
import services from "../../services/services";
import EditInputComponent from "../inputComponent/EditInputComponent";
import InputComponent from "../inputComponent/InputComponent";
import { uploadAndSetFile } from "./ReviewHelper";
import { toast } from "react-toastify";

const ReviewSourceEdit = ({ data, refreshData }: any) => {
  const [File, setFile] = useState<any>({});
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    if (showModal && data?.sourceImage) {
      setFile((prevFile: any) => ({
        ...prevFile,
        editSourcesImage: data.sourceImage,
      }));
    }
  }, [showModal, data?.sourceImage]);

  useEffect(() => {
    (formik as any).values = {};
    if (showModal) {
      (formik as any).values = {
        editSourcesName: data?.sourcesName,
        editSourcesImage: data?.sourcesImage || "",
      };
    }
  }, [showModal, data]);

  const formik: any = useFormik({
    initialValues: {
      editSourcesName: data?.sourcesName,
      editSourcesImage: data?.sourcesImage,
    },
    validationSchema: Yup.object({
      editSourcesName: Yup.string().required("Source Name is required"),
    }),
    onSubmit: async (payload) => {
      const editData = {
        sourcesName: payload.editSourcesName,
        sourcesImage: payload.editSourcesImage,
      };
      try {
        await services.editReviewSource(data?.id, editData);
        formik.resetForm();
        await refreshData();
        setShowModal(false);
        toast.success("Form updated successfully");
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form");
      }
    },
    enableReinitialize: true,
  });

  const handleChangeonSingleFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    uploadAndSetFile(e, services, setFile, formik);
  };

  const deleteOnSingleFile = async () => {
    setFile({
      ...File,
      editSourcesImage: "",
    });
    formik.setFieldValue("editSourcesImage", "");
  };

  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer"
        src={Images.edit}
        alt=""
        onClick={() => setShowModal(true)}
      />
      {showModal ? (
        <>
          <div className="justify-center  box-border  items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[950px] h-[600px] bg-white my-6 rounded overflow-y-auto ">
              {/*content*/}

              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Edit Review</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}

              <form
                onSubmit={formik.handleSubmit}
                className="w-full border border-aqua rounded-xl px-12 py-11"
              >
                <p className="font-bold font-sans text-xl">Reviews</p>
                <div className="w-full  mx-auto mt-8">
                  {editReviewSource.map((elm: any) =>
                    elm?.type === "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeonSingleFile}
                        deleteFile={deleteOnSingleFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : (
                      <InputComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                </div>
                <div className="flex justify-between mt-6"></div>
                <button
                  className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
                  type="submit"
                >
                  Add
                </button>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ReviewSourceEdit;
