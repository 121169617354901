export const utils = {
     getEngTourName : (tourName) => {
        try {
          const parsedTourName = JSON.parse(tourName);
          if (Array.isArray(parsedTourName)) {
            const obj = parsedTourName.find((item) => item.language === "en");
            if (obj && obj.text) {
              return obj.text; 
            }
            if (parsedTourName[0]?.text) {
              return parsedTourName[0].text; 
            }
          }
        } catch {
          return tourName;
        }
        return "Unknown";
      }
}